import { IS_BROWSER } from "$fresh/runtime.ts";

export const Card = ({ children, title, ...props }) => {
  return (
    <div class={"col"}>
      <div class="card" style="margin: 0.5em">
        <div class="card-body">
          <div class="card-title">
            {title}
          </div>
          <p class="card-text">{children}</p>
        </div>
      </div>
    </div>
  );
};
