import { Component } from "preact";
import { Card } from "../components/Card.tsx";

export default class StateBox extends Component {
  override async componentDidMount() {
    const { repo, last=20 } = this.props;

    const resp = await fetch(`/api/repo/${repo}`);
    let { days, processing } = await resp.json();
    if (days && days.length >= last) {
      days = days.slice(-last)
    }
    let px = !!processing;
    this.setState({ days, processing });
    while (px) {
      await new Promise((resp) => {
        setTimeout(resp, 5000);
      });
      const resp = await fetch(`/api/repo/${repo}`);
      let { days, processing } = await resp.json();
      if (days && days.length >= last) {
        days = days.slice(-last)
      }
      this.setState({ days, processing });
      px = !!processing;
    }
  }
  render({ repo }, { days, processing }) {
    if (!days) {
      return (
        <Card title={repo}>
          {processing
            ? (
              <div class="progress">
                <div
                  class="progress-bar"
                  role="progressbar"
                  style={`width: ${Math.round(processing * 100)}%`}
                  aria-valuenow="25"
                  aria-valuemin="0"
                  aria-valuemax="100"
                >
                </div>
              </div>
            )
            : (
              <div class="spinner-border" role="status">
                <span class="visually-hidden">
                  Loading...
                </span>
              </div>
            )}
        </Card>
      );
    }

    const colorFor = (ok, err) => {
      const n = ok + err;
      const r = Math.round((err / n) * 15.0);
      const g = Math.round(ok * 15.0 / n);

      return `#${r.toString(16)}${g.toString(16)}0`;
    };

    const stack = (
      <svg height={45} width={10*days.length+15}>
        {days.map(([day, ok, err], idx) => (
          <rect
            x={idx * 10 + 5}
            y={10}
            width="7"
            height="30"
            style={{
              fill: colorFor(ok, err),
              stroke: "#888",
              strokeWidth: "0.5px",
            }}
          >
            <title>
              {day} {Math.round(ok * 100 / (ok + err))}%
            </title>
          </rect>
        ))}
      </svg>
    );

    const [day, ok, err] = days[days.length - 1];
    const avl = Math.round(ok * 100 / (ok + err));

    return (
      <div class={"col"}>
        <div class="card" style="margin: 0.5em">
          <div class="card-body">
            <div class="card-title">
              <div class="row">
                <div class="col">{repo}</div>
                <div class="col align-items-end d-inline-flex">
                  <div class={"col"}>{day.slice(-5)}</div>
                  <div class={"col"}>
                    <span
                      class={`badge rounded-pill ${
                        avl < 50
                          ? "text-bg-danger"
                          : (avl > 90 ? "text-bg-success" : "text-bg-warning")
                      }`}
                    >
                      pass {avl}%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <p class="card-text">{stack}</p>
          </div>
        </div>
      </div>
    );
  }
}
